<template>

  <!-- v-transition:enter="transition ease-out duration-100" v-transition:enter-start="transform opacity-0 scale-95" v-transition:enter-end="transform opacity-100 scale-100" v-transition:leave="transition ease-in duration-75" v-transition:leave-start="transform opacity-100 scale-100" v-transition:leave-end="transform opacity-0 scale-95" --> 

   <!-- classes for sticky footer: https://dev.to/timosville/sticky-footer-using-tailwind-css-225p-->
  <div id="app" class='flex flex-col min-h-screen'> <!--   ref='main'-->

    <div v-if='showForcePayModal' class='fixed inset-0 z-10'>
      <div class='fixed fixed inset-0 bg-black opacity-50'></div>
      <div class='bg-white fixed inset-x-2 m:inset-x-12 lg:inset-x-1/5 mx-4 lg:mx-20 mt-36 lg:mt-72 rounded-lg shadow-xl border-red-400 border-2'>
        <div class="flex justify-center transform -translate-y-1/2">
          <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">Payment overdue</span>
        </div>

        <div class='p-6 xl:p-12 xxl:p-24'>
          <div class='text-left text-lg'>
            Dear {{contactStatus.contact_name}},<br /><br />
            Your TBS Membership invoice is overdue, please <a class='inline-block text-red-600' :href='invoiceStatus.link' target="_blank" rel="noopener">click here to pay</a> and unlock your account.<br /><br />
            <!-- <a class='inline-block text-red-600' href='/invoices'>Click here</a> to see your TBS membership invoice.<br /><br /> -->
            Trade Safe,<br /><br />
            Mario Biemans<br />
            Owner www.TheBrokerSite.com Empowering the Circular IT Industry since 1999!<br />
            +31 621 546 725<br />
          </div>
        </div>
      </div>
    </div>

    <div v-if='isForcedLogout' class='fixed inset-0 z-10'>
      <div class='fixed fixed inset-0 bg-black opacity-50'></div>
      <div class='bg-white fixed inset-x-2 m:inset-x-12 lg:inset-x-1/5 mx-4 lg:mx-20 mt-36 lg:mt-72 rounded-lg shadow-xl border-red-400 border-2'>
        <div class="flex justify-center transform -translate-y-1/2">
          <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">Problem with your account</span>
        </div>

        <div class='p-6 xl:p-12 xxl:p-24'>
          <div class='text-left text-lg'>
            Dear {{contactStatus.contact_name}},<br /><br />
            There was an unknown error with your account.<br />
            <a class='inline-block text-red-600 cursor-pointer' @click='signoutWrap'>Click here</a> to continue or contact us at sales@thebrokersite.com.<br /><br />
            <!-- <a class='inline-block text-red-600' href='/invoices'>Click here</a> to see your TBS membership invoice.<br /><br /> -->
            Trade Safe,<br /><br />
            Mario Biemans<br />
            Owner www.TheBrokerSite.com Empowering the Circular IT Industry since 1999!<br />
            +31 621 546 725<br />
          </div>
        </div>
      </div>
    </div>

    <div v-if='showFreeTrialEndedModal' class='fixed inset-0 z-10'>
      <div class='fixed fixed inset-0 bg-black opacity-50'></div>
      <div class='bg-white fixed inset-x-2 m:inset-x-12 lg:inset-x-1/5 mx-4 lg:mx-20 mt-36 lg:mt-72 rounded-lg shadow-xl border-red-400 border-2'>
        <div class="flex justify-center transform -translate-y-1/2">
          <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">Free Trial Ended</span>
        </div>

        <div class='p-6 xl:p-12 xxl:p-24'>
          <div class='text-left text-lg'>Dear {{contactStatus.contact_name}},<br /><br />
            Your free trial has ended.<br />
            TBS membership starts at just 675 Euro per year, <router-link to="/payment" class='inline-block text-red-600'>click here</router-link> to register and pay online to re-activate your account.
            <br /><br />
            <div class='text-left text-lg break-all' v-html="contactStatus.freeTrialFooter"></div>
          </div>
        </div>
      </div>
    </div>

    <div v-if='showCustomFullBlockModal' class='fixed inset-0 z-10'>
      <div class='fixed fixed inset-0 bg-black opacity-50'></div>
      <div class='bg-white fixed inset-x-2 m:inset-x-12 lg:inset-x-1/5 mx-4 lg:mx-20 mt-36 lg:mt-72 rounded-lg shadow-xl border-red-400 border-2'>
        <div class="flex justify-center transform -translate-y-1/2">
          <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">Important Notification</span>
        </div>

        <div class='p-6 xl:p-12 xxl:p-24 relative break-all sm:break-normal' v-html="contactStatus.customFullBlock"></div>
      </div>
    </div>

    <div v-if='showCustomFullBlockModal' class='fixed inset-0 z-10'>
      <div class='fixed fixed inset-0 bg-black opacity-50'></div>
      <div class='bg-white fixed inset-x-2 m:inset-x-12 lg:inset-x-1/5 mx-4 lg:mx-20 mt-36 lg:mt-72 rounded-lg shadow-xl border-red-400 border-2'>
        <div class="flex justify-center transform -translate-y-1/2">
          <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">Important Notification</span>
        </div>

        <div class='p-6 xl:p-12 xxl:p-24 relative break-all sm:break-normal' v-html="contactStatus.customFullBlock"></div>
      </div>
    </div>

    <div v-if='showContactSpecialMessage' class='fixed inset-0 z-10'>
      <div class='fixed fixed inset-0 bg-black opacity-50'></div>
      <div class='bg-white fixed inset-x-2 m:inset-x-12 lg:inset-x-1/5 mx-4 lg:mx-20 mt-36 lg:mt-72 rounded-lg shadow-xl border-red-400 border-2'>
        <div class="flex justify-center transform -translate-y-1/2">
          <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">Important Notification</span>
        </div>

        <div class='p-6 xl:p-12 xxl:p-24 relative break-all sm:break-normal' v-html="contactStatus.modalMessage"></div>
        <div class='absolute bottom-4 right-4'>
          <button data-v-4f0b317f="" type="button" class="ml-3 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700" @click='setModalMessageDismissed'>Close</button>
        </div>

      </div>
    </div>

    <div v-if='showContactInvoiceOverdueMessage' class='fixed inset-0 z-10'>
      <div class='fixed fixed inset-0 bg-black opacity-50'></div>
      <div class='bg-white fixed inset-x-2 m:inset-x-12 lg:inset-x-1/5 mx-4 lg:mx-20 mt-36 lg:mt-72 rounded-lg shadow-xl border-red-400 border-2'>
        <div class="flex justify-center transform -translate-y-1/2">
          <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">Invoice is overdue</span>
        </div>

        <div class='p-6 xl:p-12 xxl:p-24'>
          <div class='text-left text-lg'>Dear {{contactStatus.contact_name}},<br /><br />
            Your TBS Membership invoice is overdue, please <a class='inline-block underline text-red-600' :href='invoiceStatus.link' rel="noopener">click here</a> to pay it online now and keep your account active.
          </div>
        </div>
        <div class='absolute bottom-4 right-4'>
          <button data-v-4f0b317f="" type="button" class="ml-3 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700" @click='setInvoiceOverdueMessageDismissed'>Close</button>
        </div>
      </div>
    </div>

    <div v-if='showContactEbmInvoiceOverdueMessage' class='fixed inset-0 z-10'>
      <div class='fixed fixed inset-0 bg-black opacity-50'></div>
      <div class='bg-white fixed inset-x-2 m:inset-x-12 lg:inset-x-1/5 mx-4 lg:mx-20 mt-36 lg:mt-72 rounded-lg shadow-xl border-red-400 border-2'>
        <div class="flex justify-center transform -translate-y-1/2">
          <span class="inline-flex rounded-full bg-red-600 px-4 py-1 text-sm font-semibold tracking-wider uppercase text-white">Invoice is overdue</span>
        </div>

        <div class='p-6 xl:p-12 xxl:p-24'>
          <div class='text-left text-lg'>Dear {{contactStatus.contact_name}},<br /><br />
            Your EBM Attendance invoice is due, please <a class='inline-block underline text-red-600' :href='invoiceStatus.link' rel="noopener">click here</a> to pay it online now.
          </div>
        </div>
        <div class='absolute bottom-4 right-4'>
          <button data-v-4f0b317f="" type="button" class="ml-3 text-sm btn btn-muted text-red-700 border-red-700 hover:text-red-700" @click='setInvoiceOverdueMessageDismissed'>Close</button>
        </div>
      </div>
    </div>

    <div v-if='!hideCookiesPopup && isFirstLoad' class="relative bg-red-600">
      <div class="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
        <div class="pr-16 sm:text-center sm:px-16">
          <p class="font-medium text-white">
            <span class="inline">
              TheBrokerSite.com uses cookies to authenticate you on our site. By using our site, you agree to our
            </span>
            <span class="block sm:ml-2 sm:inline-block">
              <a href="/privacy" rel="noopener" class="text-white font-bold underline"> Privacy Policy <span aria-hidden="true">&rarr;</span></a>
            </span>
          </p>
        </div>
        <div class="absolute inset-y-0 right-0 pt-1 pr-1 flex items-start sm:pt-1 sm:pr-2 sm:items-start">
          <button @click='doHideCookiesPopup' type="button" class="flex p-2 rounded-md hover:bg-red-500 focus:outline-none focus:ring-2 focus:ring-white">
            <span class="sr-only">Dismiss</span>
            <!-- Heroicon name: x -->
            <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <modal v-show="doShowSigninModal" @submit="submitSignin" @cancel="doShowSigninModal=false" :doSkipHeader="true" :doSkipFooter="true" >
      <template v-slot:body>
        <div class="max-w-md w-full">
          <div>
            <h2 class="mt-6 text-center text-2xl sm:text-3xl leading-9 font-bold text-gray-900">
              Sign in to your account
            </h2>
          </div>
          <form class="mt-8" @submit.prevent="submitSignin">
            <div class="rounded-md shadow-sm">
              <div>
                <input v-model='loginPayload.email' aria-label="Email address" name="email" type="email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Email address">
              </div>
              <div class="-mt-px">
                <input v-model='loginPayload.password' aria-label="Password" name="password" type="password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Password">
              </div>
            </div>

            <div class="mt-6 flex items-center justify-between">
              <div class="flex items-center">
                <a href="javascript:void(0)" @click='showPasswordResetModal' class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">Forgot your password?</a>
              </div>
              <div class="text-sm leading-5">
                <a href="javascript:void(0)" @click='toPaymentPage' class="font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:underline transition ease-in-out duration-150">Register as a paid member</a>
              </div>
            </div>

            <div class="mt-6">
              <button type='submit'  class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg class="h-5 w-5 text-red-500 group-hover:text-red-400 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                  </svg>
                </span>
                Sign in
              </button>
            </div>

          </form>
        </div>
      </template>
    </modal>

    <modal v-show="doShowPasswordResetModal" @submit="submitPasswordReset" @cancel="doShowPasswordResetModal=false" :doSkipHeader="true"  :doSkipFooter="true" >
      <template v-slot:body>
        <div class="max-w-md w-full">
          <div>
            <h2 class="mt-6 text-center text-2xl sm:text-3xl leading-9 font-bold text-gray-900">Password Reset</h2>
          </div>
          <div class='mt-4'>Please enter your email address below. If we have your email-address on file, we will send you instructions on how to reset your password.</div>

          <form class="mt-4" @submit.prevent="submitPasswordReset">
            <input v-model='passwordResetEmail' aria-label="Email address" name="email" type="email" required class="appearance-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:text-sm sm:leading-5" placeholder="Email address">

            <div class="mt-6">
              <button type='submit'  class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red active:bg-red-700 transition duration-150 ease-in-out">
                <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                  <svg class="h-5 w-5 text-red-500 group-hover:text-red-400 transition ease-in-out duration-150" fill="currentColor" viewBox="0 0 20 20">
                    <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                  </svg>
                </span>
                Request Email
              </button>
            </div>
          </form>
        </div>
      </template>
    </modal>

    <modal v-show="showChangePasswordModal" @submit="submitChangePassword(resetToken)" @cancel="showChangePasswordModal=false"
			header="Reset Password" 
			affirmativeButtonLabel='Reset Password'
			:disableSubmit='passwordSubmitDisabled'
			>
			<template v-slot:body>
				<div>
					<dl class='mt-4 mb-4 sm:-ml-6 sm:-mr-6'>
						<div class=" py-2 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
							<dt class="text-sm leading-9 font-medium text-gray-500">New Password</dt>
							<dd class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0 sm:col-span-3">
								<password v-model="resetPasswordInput" @feedback="passwordFeedBack" defaultClass='form-input block w-full' :toggle='true' @score='passwordScoreFeedback'/>
								<div class='text-blue-600' v-if='passwordSuggestion'>{{passwordSuggestion}}</div>
							</dd>
						</div>
					</dl>
				</div>
		  </template>
		</modal>

    <nav class="bg-red-700">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <router-link to="/"><img src="@/assets/tbs_logo_25.png" alt="The Broker Site" width=175 /></router-link>
            </div>
            <div class="hidden lg:block">
              <div class="ml-10 flex items-center">

                <div>
                  <div class="mt-1 flex rounded-md shadow-sm">
                    <div class="relative flex-grow focus-within:z-10">
                      
                      <div class="absolute inset-y-0 left-0 flex items-center border-0 border-r border-gray-300">
                        <select aria-label="Country" 
                          class="form-select pl-2 pr-7 border-transparent bg-transparent text-gray-500 rounded-none rounded-l focus:border-red-300 focus:shadow-outline-red text-sm"
                          v-model="searchCat">
                          <option v-for="option in searchOptions" :key='option.value' :value='option.value'>{{option.label}}</option>
                        </select>
                      </div>
                      <input 
                        class="form-input block w-80 pl-32 text-sm leading-5 focus:border-red-300 focus:shadow-outline-red " 
                        placeholder="Search"
                        v-model="searchInput"
                        @keyup.enter="doSearch"
                      >
                      <!-- rounded-none rounded-l-md -->
                    </div>
                    <!-- <button class="-ml-px relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-r-md text-gray-700  hover:text-gray-500 hover:bg-white focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150">
                      <span>Search</span>
                    </button> -->
                  </div>
                </div>

                <div v-if='user'>
                  <router-link to="/broadcasts" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'broadcasts'}">Broadcasts</router-link>
                  <router-link to="/parts" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'parts'}">Parts</router-link>
                  <router-link to="/companies" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'companies'}">Companies</router-link>
                  <router-link v-if='user.isServiceOnly===0' to="/inventory" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'inventory'}">Inventory</router-link>
                  <router-link to="/services" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'services'}">Services</router-link>
                  <router-link v-if='user.isServiceOnly===1' to="/contact" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'contact'}">Contact us</router-link>
                  <router-link to="/news" class="nav-item relative" :class="{'nav-item--active': topLevelRoute === 'news'}">News
                    <div v-if='numFreshNews' class='tag absolute left-13 top-2 -mt-1 bg-yellow-300 text-red-900 text-xs px-1 py-0 leading-tight'>{{numFreshNews}}</div>
                  </router-link>
                </div>
                <div v-else>
                  <router-link to="/testimonials" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'testimonials'}">Testimonials</router-link>
                  <router-link :to="{name: 'freeTrial'}" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'register'}" >Free Trial</router-link>
                  <router-link to="/news" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'news'}">News</router-link>
                  <router-link to="/about" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'about'}">About</router-link>
                  <router-link to="/services" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'services'}">Services</router-link>
                  <!-- <router-link to="/contact" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'contact'}">Contact us</router-link> -->
                  <!-- <router-link to="/help" class="nav-item" :class="{'nav-item--active': topLevelRoute === 'help'}">Help</router-link> -->
                </div>
              </div>
            </div>
          </div>
          <div class="hidden lg:block">
            <div class=" ml-4 flex items-center lg:ml-6">
              <!-- <button  @click='toNews' v-if='user'  class="relative p-1 border-2 border-transparent text-red-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-600" aria-label="Notifications">
                <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
                </svg>
                <div v-if='numFreshNews' class='tag absolute right-0 top-0 -mt-1 bg-yellow-300 text-red-900 text-xs px-1 py-0 leading-tight'>{{numFreshNews}}</div>
              </button> -->

              <div v-if='user' class="ml-3 relative">
                <div>
                  <button v-on-clickaway="closeDropdown" @click="isUserDropdownOpen = !isUserDropdownOpen"  class="relative p-1 border-2 border-transparent text-red-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-600" aria-label="Notifications">
                    <div v-if='profilePic' class="h-8 w-8 rounded-full" alt="" :style='userPicCSS'></div>
                    <svg v-else class="h-6 w-6" stroke="none" fill="none" viewBox="2 2 16 16">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z" fill="currentColor"/>
                    </svg>
                  </button>
                </div>

                <!-- Include transitions here -->
                <div v-show="isUserDropdownOpen"  class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-20">
                  <div class="py-1 rounded-md bg-white shadow-xs">
                    <router-link to="/profile" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Your Profile</router-link>
                    <router-link to="/favorites" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Your Favorites</router-link>
                    <router-link to="/invoices" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Billing History</router-link>
                    <router-link to="/help" class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Help</router-link>
                    <a href="javascript:void(0)" @click='signoutWrap' class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100">Sign out</a>
                  </div>
                </div>
              </div>
              <button v-if='!user' @click='showSignInModal' class="font-semibold relative p-1 border-2 border-transparent text-white rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-red-600" >
                Sign In
              </button>

            </div>
           <!--  <div v-else class="ml-4 flex items-center lg:ml-6">
              <button class="ml-4 px-3 py-2 rounded-md text-sm font-medium text-red-200 hover:text-white hover:bg-red-600 focus:outline-none focus:text-white focus:bg-red-600" aria-label="Sign in">
                Sign In
              </button>
            </div> -->
          </div>
          <div class="-mr-2 flex lg:hidden">
            <button @click.stop="isUserDropdownOpen = !isUserDropdownOpen" class="inline-flex items-center justify-center p-2 rounded-md text-red-300 hover:text-white hover:bg-red-600 focus:outline-none focus:bg-red-600 focus:text-white" v-bind:aria-label="isUserDropdownOpen ? 'Close main menu' : 'Main menu'" v-bind:aria-expanded="isUserDropdownOpen">
              <svg class="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path :class="{'hidden': isUserDropdownOpen, 'inline-flex': !isUserDropdownOpen }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                <path :class="{'hidden': !isUserDropdownOpen, 'inline-flex': isUserDropdownOpen }" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="lg:hidden" :class="{'block': isUserDropdownOpen, 'hidden': !isUserDropdownOpen}" >
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <div v-if='user'>
            <router-link to="/broadcasts" class="nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'broadcasts'}">Broadcasts</router-link>
            <router-link to="/parts" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'parts'}">Parts</router-link>
            <router-link to="/companies" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'companies'}">Companies</router-link>
            <div v-if='user.isServiceOnly===0'>
              <router-link to="/inventory" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'inventory'}">Inventory</router-link>
            </div>
            <router-link to="/news" class="mt-1 nav-item-mobile relative" :class="{'nav-item-mobile--active': topLevelRoute === 'news'}">News
              <div v-if='numFreshNews' class='tag absolute left-15 top-3 -mt-1 bg-yellow-300 text-red-900 text-xs px-1 py-0 leading-tight'>{{numFreshNews}}</div>
            </router-link>
            <router-link to="/services" class="mt-1 nav-item-mobile" :class="{'nav-item--active': topLevelRoute === 'services'}">Services</router-link>
            <router-link to="/contact" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'contact'}">Contact us</router-link>
          </div>
          <div v-else>
            <router-link to="/testimonials" class="nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'testimonials'}">Testimonials</router-link>
            <router-link :to="{name: 'freeTrial'}" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'register'}">Free Trial</router-link>
            <router-link to="/news" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'news'}">News</router-link>
            <router-link to="/about" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'about'}">About</router-link>
            <router-link to="/contact" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'contact'}">Contact us</router-link>
            <!-- <router-link to="/help" class="mt-1 nav-item-mobile" :class="{'nav-item-mobile--active': topLevelRoute === 'help'}">Help</router-link> -->
          </div>
        </div>

        <div v-if="user" class="pt-4 pb-3 border-t border-red-800">
          <div class="flex items-center px-5">
            <div class="flex-shrink-0">
              <div v-if='profilePic' class="h-8 w-8 rounded-full" alt="" :style='userPicCSS'></div>
              <svg v-else class="h-6 w-6" stroke="none" fill="none" viewBox="2 2 16 16">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z" fill="white"/>
              </svg>
            </div>
            <div class="ml-3">
              <div class="text-base font-medium leading-none text-white">{{user.name}}</div>
              <div class="mt-1 text-sm font-medium leading-none text-red-300">{{user.email}}</div>
            </div>
          </div>
          <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <router-link to="/profile" class="nav-item-mobile" role="menuitem" :class="{'nav-item-mobile--active': topLevelRoute === 'profile'}">Your Profile</router-link>
            <router-link to="/favorites" class="nav-item-mobile" role="menuitem" :class="{'nav-item-mobile--active': topLevelRoute === 'profile'}">Your Favorites</router-link>
            <router-link to="/invoices" class="nav-item-mobile" role="menuitem" :class="{'nav-item-mobile--active': topLevelRoute === 'profile'}">Billing History</router-link>
            <router-link to="/help" class="nav-item-mobile" role="menuitem" :class="{'nav-item-mobile--active': topLevelRoute === 'profile'}">Help</router-link>
            <a href="javascript:void(0)" @click='signoutWrap' class="mt-1 nav-item-mobile" role="menuitem">Sign out</a>
          </div>
        </div>
        <div v-else class="pt-1 pb-3 border-t border-red-800">
           <div class="mt-3 px-2" role="menu" aria-orientation="vertical" aria-labelledby="user-menu">
            <a href="javascript:void(0)" @click='showSignInModal' class="mt-1 nav-item-mobile" role="menuitem">Sign In</a>
          </div>
        </div>

      </div>
    </nav>
    <nav v-if='user' class="bg-red-100">
      <div class="max-w-7xl mx-auto px-4 py-1 sm:px-6 lg:px-8 text-red-900 text-sm">
        <div class="float-left pb-1.5">
          <span>Welcome {{user.name}}</span>
          <span v-if='invoiceStatus.isPastDue && !invoiceStatus.isEbmInvoice && !invoiceStatus.isNoNotif' class='text-red-600 font-semibold'> - <span class="blinkText">Your TBS membership invoice is overdue, please pay immediately by <a class='inline-block underline' :href='invoiceStatus.link' rel="noopener">clicking here</a></span></span>
          <!-- <span v-else-if='invoiceStatus.isPastDueEbm && invoiceStatus.isEbmInvoice && !invoiceStatus.isNoNotif' class='text-red-600 font-semibold'> - <span class="blinkText">Your EBM Attendance invoice is due, please <a class='inline-block underline' :href='invoiceStatus.link' rel="noopener">click here</a> to pay it now</span></span> -->
          <span v-else-if='invoiceStatus.isDue && !invoiceStatus.isEbmInvoice && !invoiceStatus.isNoNotif' class='text-red-600 font-semibold'> - Your TBS membership invoice is due, <a class='inline-block underline' :href='invoiceStatus.link' rel="noopener">click here</a> to pay now</span>
          <span v-else-if='!profilePic'> - To complete your profile, please <router-link to="/profile" class='inline-block underline'>upload your profile picture</router-link></span>
          <span v-else-if='doUnapprovedRecsExist'> - You have recommendations to approve, <router-link to="/profile" class='inline-block underline'>click here</router-link> to go to your profile</span>
        </div>
        <div class="float-right pb-1.5 invisible md:visible">
          <span v-if='!invoiceStatus.isPastDue && !invoiceStatus.isDue && !doUnapprovedRecsExist'>
            <span class="pr-2">HKG {{this.loadWorldClock(tzHkg)}}</span><span class="pr-2">|</span>
            <span class="pr-2">AMS {{this.loadWorldClock(tzAms)}}</span><span class="pr-2">|</span>
            <span class="pr-2">LDN {{this.loadWorldClock(tzLdn)}}</span><span class="pr-2">|</span>
            <span class="pr-2">NYC {{this.loadWorldClock(tzNyc)}}</span><span class="pr-2">|</span>
            <span class="pr-0">LA {{this.loadWorldClock(tzLa)}}</span>
          </span>
        </div>
      </div>
    </nav>
    <header class="bg-white shadow-sm" v-if="!doSkipHeader">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <slot name="header" v-if='withHeaderStructure'></slot>
        <h1 v-else class="text-3xl mt-5 mb-3 leading-6 text-gray-900 font-bold tracking-tight">
          <slot name="header"></slot>
        </h1>
      </div>
    </header>
    <main class="flex-grow">
      <div class="max-w-7xl mx-auto sm:py-6 sm:px-6 lg:px-8">


        <!--  LOGIN
        <div class="px-4 py-0 sm:px-0">
          <nav class="hidden sm:flex items-center text-sm leading-5 font-medium">

            <span class="ml-3 shadow-sm rounded-md">
              <button type="button" class="inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:shadow-outline-indigo focus:border-indigo-700 active:bg-indigo-700 transition duration-150 ease-in-out">
                Log In
              </button>
            </span>

            <a href="#" class="text-red-500 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Log In</a>
            <a href="#" class="text-red-500 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Forgot your password?</a>
            <router-link to="/payment" class="text-red-500 hover:text-red-700 focus:outline-none focus:underline transition duration-150 ease-in-out">Register as a paid member</router-link>
          </nav>
        </div> -->

        <div class="px-4 sm:py-2 sm:px-0">
          <slot name="main"></slot>
        </div>

        <!-- banners -->
        <div class='px-4 mt-10 mb-4 invisible lg:visible '>
          <div class="flex -mx-8">
            <div v-for="banner in bannersEnriched" :alt='banner.alt' :key='banner.id'  class='banner-item w-1/5 px-8'>
              <a href='javascript:void(0)' @click='navigateBanner(banner)' class='rounded bg-white shadow border-b border-gray-200 h-full block' :style="banner.style"></a>
            </div>
          </div>
        </div>
      </div>
    </main>


    <!-- EBM Companies ticker //-->
    <div class="max-w-7-xl mt-8 cursor-pointer" @click="navigateTicker()">
      <div class="hidden lg:block relative w-full mx-auto mt-6">
        <div v-if="lastRegistered" class="home-ticker-container bg-red-600 text-white text-sm whitespace-nowrap overflow-hidden">
          <div class="home-ticker py-4 ">
            <span>{{ lastRegistered }}</span>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="bg-gray-900">
      <div class="max-w-screen-xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav class="-mx-5 -my-2 flex flex-wrap justify-center">
          <div class="px-5 py-2">
            <router-link to="/payment" class="text-base leading-6 text-gray-300 hover:text-white">
              Pay TBS Membership
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/about" class="text-base leading-6 text-gray-300 hover:text-white">
              About
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/media-kit" class="text-base leading-6 text-gray-300 hover:text-white">
              Media Kit
            </router-link>
          </div>
          <!-- <div class="px-5 py-2">
            <a href="#" class="text-base leading-6 text-gray-300 hover:text-white">
              Site Map
            </a>
          </div> -->
          <div class="px-5 py-2">
            <router-link to="/contact" class="text-base leading-6 text-gray-300 hover:text-white">
              Contact us
            </router-link>
          </div>
          <div v-if='user' class="px-5 py-2">
            <router-link to="/help" class="text-base leading-6 text-gray-300 hover:text-white">
              Help
            </router-link>
          </div>
          <div class="px-5 py-2">
            <router-link to="/privacy" class="text-base leading-6 text-gray-300 hover:text-white">
              Privacy Policy
            </router-link>
          </div>
          <div class="px-5 py-2">
            <a href='http://eepurl.com/bG65cr' target='_blank' rel='noopener' class="text-base leading-6 text-gray-300 hover:text-white">
              Subscribe to our Newsletter
            </a>
          </div>
        </nav>
        <div class="mt-8 flex justify-center gap-4">

         
          <a href="https://twitter.com/TheBrokerSite" rel="noopener" class="ml-6 text-gray-300 hover:text-white">
            <span class="sr-only">Twitter</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84"/>
            </svg>
          </a>

          <a href="https://www.linkedin.com/company/the-broker-site/"  rel="noopener" class="text-gray-300 hover:text-white">
            <span class="sr-only">LinkedIn</span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-2 16h-2v-6h2v6zm-1-6.891c-.607 0-1.1-.496-1.1-1.109 0-.612.492-1.109 1.1-1.109s1.1.497 1.1 1.109c0 .613-.493 1.109-1.1 1.109zm8 6.891h-1.998v-2.861c0-1.881-2.002-1.722-2.002 0v2.861h-2v-6h2v1.093c.872-1.616 4-1.736 4 1.548v3.359z"/>
            </svg>
          </a>

          <a href="https://www.facebook.com/thebrokersite" rel="noopener" class="text-gray-300 hover:text-white">
            <span class="sr-only">Facebook</span>
            <svg class="h-6 w-6" fill="currentColor" viewBox="0 0 24 24">
              <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd"/>
            </svg>
          </a>

        </div>
        <div class="mt-8">
          <p class="text-center text-base leading-6 text-gray-400">
            &copy; Copyright 1999 - {{new Date().getFullYear()}} The Broker Site BV. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>




<script>
import Vue from 'vue';
import _ from 'lodash'
import { mixin as clickaway } from 'vue-clickaway';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex'

import resetPassword from '../components/mixins/resetPassword'
import eventBus from '../utils/eventBus'

import Modal from '@/components/modal.vue'

export default {
  name: 'BaseLayout',
  props: {
    doSkipHeader: Boolean,
    withHeaderStructure: Boolean,
  },
  mixins: [ clickaway, resetPassword],
  components: {
    Modal,
  },
  
  data(){
    return {
      searchInput: null,
      searchCat: "broadcasts",
      isUserDropdownOpen: false,
      doShowSigninModal: false, 
      doShowPasswordResetModal: false, 
      passwordResetEmail: '',
      resetToken: null,
      loginPayload: {
        email: null, 
        password: null,
      },
      tzHkg: 'Asia/Hong_Kong',
      tzAms: 'Europe/Amsterdam',
      tzLdn: 'Europe/London',
      tzNyc: 'America/New_York',
      tzLa: 'America/Los_Angeles',
      lastRegistered: null,
    }
  },
  async created(){
    this.searchCat = this.user ? "broadcasts" : "parts"

    try {
      const names = await this.$http.get("/api/misc/lastRegistered").then(res => res.data.payload)
      // this.lastRegistered = `Latest registered attendees for the 2024 EBM in Cyprus: \xA0 \xA0  ${names.join(" \xA0 \xA0 • \xA0 \xA0 ")}`
      this.lastRegistered = `Winners of the free #EBM2025 tickets: \xA0 \xA0  C&M IT Remarketing \xA0 \xA0 • \xA0 \xA0 Aucnet \xA0 \xA0 • \xA0 \xA0 Ecotech Solutions \xA0 \xA0 • \xA0 \xA0 Hibco General LLC \xA0 \xA0 • \xA0 \xA0 Rapid Solutions \xA0 \xA0 \xA0 \xA0 • \xA0 \xA0 \xA0 \xA0 At the 20th #EBM2024 we raised €25,000 for IT4Kids! \xA0 \xA0 \xA0 \xA0 • \xA0 \xA0 \xA0 \xA0 The 21st #EBM2025 will be held on November 5, 6 & 7 2025 in Dublin!`
    } catch (err) {
      console.log("err", err)
    }

// When use receives mail with resetToken
    if(this.$route.query.reset) {
      this.resetToken = this.$route.query.reset;
      const tokenVerifyPayload = await this.verifyResetToken({ resetToken: this.$route.query.reset})

      if(tokenVerifyPayload.status !== 200 || (tokenVerifyPayload.data.payload && !tokenVerifyPayload.data.payload.isSuccess)){
        const errorMessage = tokenVerifyPayload.data.payload.msg;

        eventBus.$emit("createSnackbar", {
          type: "error", 
          title: "Password reset token invalid",
          body: errorMessage,
          timeoutInMS: 5000,
        })
        return
      }

      this.showChangePasswordModal = true;
      
    }
  },
  async mounted(){
    this.loadChat()
  },
  computed: {
    ...mapState(["route", "user", 'numFreshNews', 'hideCookiesPopup', 'profilePic', 'invoiceStatus', 'invoices', 'contactStatus', 'isModalMessageDismissed', 'isInvoiceOverdueMessageDismissed', 'doUnapprovedRecsExist', 'forceLogout']),
    ...mapGetters(["bannersEnriched", 'isFirstLoad', 'userPicCSS']),
    topLevelRoute(){
      return this.route.path.slice(1).split("/")[0];
    },
    searchOptions(){
      if (this.user) {
        return [
          { label: "Broadcasts", value: "broadcasts"},
          { label: "Parts", value: "parts" },
          { label: "Companies", value: "companies" },
        ]
      } else {
        return [
          { label: "Parts", value: "parts" },
          { label: "Companies", value: "companies" },
        ]
      }
    },
    showForcePayModal(){
      return this.user && this.invoiceStatus.forcePay && this.route.name !== 'invoice'
    },
    showFreeTrialEndedModal(){
      return this.user && !this.invoiceStatus.forcePay && this.contactStatus.freeTrialBlock && this.route.path !== '/payment'
    },
    showCustomFullBlockModal() {
      return this.user && !this.invoiceStatus.forcePay && this.contactStatus.customFullBlock && this.route.path !== '/payment'
    },
    showContactSpecialMessage(){
      return this.user && !this.showForcePayModal && !this.showFreeTrialEndedModal && this.contactStatus.modalMessage && !this.isModalMessageDismissed
    },
    showContactInvoiceOverdueMessage(){
      // && !isInvoiceOverdueMessageDismissed
      return this.user && !this.showForcePayModal && !this.showFreeTrialEndedModal && this.invoiceStatus.isPastDue && !this.invoiceStatus.isEbmInvoice && !this.invoiceStatus.isNoNotif && !this.isInvoiceOverdueMessageDismissed
    },
    showContactEbmInvoiceOverdueMessage(){
      // && !isInvoiceOverdueMessageDismissed
      // console.log(this.invoiceStatus);
      return this.user && !this.showForcePayModal && !this.showFreeTrialEndedModal && this.invoiceStatus.isPastDueEbm && this.invoiceStatus.isEbmInvoice && !this.invoiceStatus.isNoNotif && !this.isInvoiceOverdueMessageDismissed
      
      // we temporarily set this to false so that it doesn't show
      // return false
    },
    showWorldClock() {
      return this.user && !this.invoiceStatus.forcePay && !this.contactStatus.freeTrialBlock && !this.contactStatus.customFullBlock && !this.invoiceStatus.isPastDue && !this.invoiceStatus.isNoNotif && !this.isInvoiceOverdueMessageDismissed
    },
    isForcedLogout() {
      return this.user && this.user.forceLogout
    },
  },
  watch: {
    user(){
      this.searchCat = this.user ? "broadcasts" : "parts"
    },
  },
  methods: {
     ...mapActions(["signout", "signin", 'incrementClickForBanner', 'resetPassword', 'requestPassword','verifyResetToken']),
    ...mapMutations(['doHideCookiesPopup', 'setModalMessageDismissed', 'setInvoiceOverdueMessageDismissed']),
    clearLoginForm(){
      this.loginPayload = {
        email: null, 
        password: null,
      }
    },
    showSignInModal(){
      this.clearLoginForm();
      this.doShowSigninModal = true;
      this.doShowPasswordResetModal = false;
    },
    showPasswordResetModal(){
      this.doShowSigninModal = false;
      this.doShowPasswordResetModal = true;
    },
    signoutWrap(){
      this.signout()
      this.$router.go(this.$router.currentRoute)
    },
    submitSignin(){
      this.signin(this.loginPayload)
      .then(() => {

        const route = this.$route;
        
        if(this.$route.query.reset){
          this.$router.replace({ query: {} });
        }

        this.$router.go(route)
      })
      .catch(err => {

        if(err.status === 400){
          eventBus.$emit("createSnackbar", {
            type: "error", 
            title: "Could not login",
            body: "Your email address and/or password is incorrect. <br/> Please try again or click 'Forgot your Password?' to reset it.",
            timeoutInMS: 5000,
          })

        } else {
          eventBus.$emit("createSnackbar", {
            type: "error", 
            title: "Something went wrong",
            body: "Could not login. Please try again. <br/> If the problem persists please contact The Broker Site",
            timeoutInMS: 5000,
          })
        }
        
      })
    },
    submitPasswordReset(){

      this.requestPassword({ email: this.passwordResetEmail})
      .then(resp => {

        if(resp.data.status !== 200) throw resp;
        this.doShowPasswordResetModal = false;

        eventBus.$emit("createSnackbar", {
   				type: "success", 
   				title: "Password request successfull.",
          body: "If we have your email-address on file, you will receive an email with instructions soon.",
   				timeoutInMS: window.tbs.isTestMode ? 10000 : undefined,
   			})

      }).catch(err => {
        eventBus.$emit("createSnackbar", {
          type: "error", 
          title: "Something went wrong",
          body: "Requesting a password reset failed. Please try again. <br/> If the problem persists please contact The Broker Site",
          timeoutInMS: 5000,
        })
      })
    },
    doSearch(){
      if(!this.searchInput) return this.$router.push({path: `/${this.searchCat}`});
      if(this.searchCat === 'companies'){
        this.$router.push({path: `/${this.searchCat}?query=${this.searchInput}&showSearch=true` });
      } else {
        this.$router.push({path: `/${this.searchCat}?query=${this.searchInput}` });
      }
    },
    toPaymentPage(){
      this.$router.push({name: `payment` });
    },
    toNews(){
      this.$router.push({name: `news` });
    },
    closeDropdown(){
      if(this.isUserDropdownOpen){
        this.isUserDropdownOpen = false;
      }
    },
    navigateBanner(banner){

      if(banner.isAd){
        this.incrementClickForBanner(banner.id);
      }
      window.open(banner.url, '_blank');
    },
    navigateTicker(){
      const newWindow = window.open('https://www.europeanbrokermeeting.com', '_blank');
      if (newWindow) newWindow.opener = null;
    },
    loadWorldClock(strTimeZone) {
      var tDate = new Date();
      const option={ timeZone: strTimeZone, hourCycle: 'h23', hour: '2-digit', minute: '2-digit' }
      return tDate.toLocaleTimeString('en-US', option)
    },
    loadChat(){
      const tawkAPI = window.Tawk_API = window.Tawk_API || {}

      // For logged in user, set name + email
      if(this.user) {
        tawkAPI.visitor = {
          name  : `${this.user.name} (${this.user.companyName})`,
          email : this.user.email
        };
      }
      
      // maximize chat for anonymous users
      tawkAPI.onLoad = () => {
        if(!this.user){
          tawkAPI.maximize()
        } 
      };

      // Load Tawk script
      var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
      s1.async=true;
      s1.src='https://embed.tawk.to/60f9399c649e0a0a5ccd68b9/1fb6oi34u';
      s1.charset='UTF-8';
      s1.setAttribute('crossorigin','*');
      s0.parentNode.insertBefore(s1,s0);
    },
  }
}

</script>

<style scoped>
@-webkit-keyframes ticker {
  0% {
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(60%, 0, 0);
    visibility: visible;
  }

  100% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
}

@keyframes ticker {
  0% {
    transform: translate3d(100%, 0, 0); /* Start just outside of the right edge */
  }
  100% {
    transform: translate3d(-100%, 0, 0); /* Move to twice the width to the left */
  }
}

.home-ticker-container {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 32px;
  z-index: 2;
}

.home-ticker {
  position: absolute;
  bottom: 0;
  height: 42px;
  z-index: 3;

  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-name: ticker;
  animation-name: ticker;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
}
</style>

<style>
  .banner-item:before { /* dynamic height */
    content:'';
    float:left;
    padding-top:100%;
  }
</style>
